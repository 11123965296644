import React, { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import AppProvider from './Context/Context';
import { router } from './routes/routes';
import ComingSoon from "./Coming Soon/src/App";
import TournamentContextProvider from './Context/TournamentContext';
import { fetchWinners } from './utils/functions/fetchData';

function App() {
  const [winners, setWinners] = useState([]); // State to hold winners data
  const [isLoading, setIsLoading] = useState(true); // State to manage loading status
  const production = false;

  useEffect(() => {
    const getWinners = async () => {
      try {
        const winnersData = await fetchWinners();
        setWinners(winnersData); // Set the winners data
      } catch (error) {
        console.error("Error fetching winners:", error);
      } finally {
        setIsLoading(false); // Set loading to false after fetching
      }
    };

    getWinners(); // Call the async function
  }, []); // Run only on mount

  if (isLoading) {
    return (
      <div className="loading-screen">
        <div className="loader"></div>
      </div>
    );
  }

  if (production) {
    return (
      <AppProvider winners={winners}> {/* Pass winners here */}
        <TournamentContextProvider>
          <RouterProvider router={router} />
        </TournamentContextProvider>
      </AppProvider>
    );
  } else {
    return (
      <>
        <ComingSoon />
      </>
    );
  }
}

export default App;
