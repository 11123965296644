import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const PublicRoute = ({ children, redirectPath }) => {

    const isAuthenticated = useAuth();
    const isDataAvail = false;
  if (isAuthenticated && isDataAvail) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default PublicRoute;