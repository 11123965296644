import axios from "axios";
const url = `${process.env.REACT_APP_BACKEND}`;

export const fetchWinners = async() => {
    const response = await axios.get(`${url}/api/v1/getWinners`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response.items

}




