import { useContext, useEffect, useState } from "react";
import TournamentCard from "../TournamentCard/TournamentCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AnimatePresence, motion } from "framer-motion";
import DropDown from "../DropDown/DropDown";
import SkeletonTournamentCard from "./Skeleton";
import { AppContext } from "../../Context/Context";

// TournamentSection component
export default function TournamentSection({
  title,
  status,
  width,
  cards,
  types,
  setDetails,
  details,
  numOfCards,
  isViewAllActive,
  setIsViewAllActive,
  isLoading
}) {
  const [type, setType] = useState("blitz");
  const { isSigned, setSignIn} = useContext(AppContext)
  return (
    <section className="TournamentBox">
      <TournamentSectionHead
        status={status}
        types={types}
        type={type}
        setType={setType}
        isViewAllActive={isViewAllActive}
        setIsViewAllActive={setIsViewAllActive}
      />
{isViewAllActive ? (
  <div className="allCards">
    <div className="cards">
      {isLoading ? (
        Array(numOfCards).fill(null).map((_, index) => (
          <SkeletonTournamentCard key={index} />
        ))
      ) : (
        cards
          .filter((elem) => (elem.tournamentType === type ))
          .map((elem) => (
            <TournamentCard
              key={elem.id}
              status={status}
              card={elem}
              setDetails={setDetails}
              isLoading={isLoading}
            />
          ))
      )}
    </div>
  </div>
) : (
  <Crousel
    status={status}
    width={width}
    cards={cards}
    type={type}
    setDetails={setDetails}
    details={details}
    numOfCards={numOfCards}
    isLoading={isLoading}
  />
)}
    </section>
  );
}

function TournamentSectionHead({
  status,
  types,
  type,
  setType,
  isViewAllActive,
  setIsViewAllActive,
}) {
  const { isSigned, setSignIn} = useContext(AppContext)
  return (
    <div className="head">
      <DropDown type={type} types={types} setType={setType}  setIsViewAllActive={setIsViewAllActive} status={status}/>
      <button className="status headBtn active">{status}</button>
      {/* <button
        className="viewAll headBtn"
        onClick={() =>
          setIsViewAllActive(isViewAllActive === status ? "" : status)
          
        }
      >
        {!isViewAllActive ? "View All" : "View Less"}
      </button> */}
    </div>
  );
}

function Crousel({
  status,
  width,
  cards,
  type,
  setDetails,
  details,
  numOfCards,
  isLoading
}) {
  const specificTypeCards = cards?.filter((elem) => elem.tournamentType === type);
  const { isSigned, setSignIn} = useContext(AppContext)
  return (
    <div className="crousel">
      {isLoading ? (
        <SliderCompo
          cards={Array(numOfCards).fill({})}
          numOfCards={numOfCards}
          status={status}
          setDetails={setDetails}
          details={details}
          isLoading={isLoading}
        />
      ) : !isSigned&& status!="Upcoming"? <div className="noData"> <a style={{color : "gray" , textDecorationLine : "none"}} href="/login">Please login </a></div> :specificTypeCards.length === 0 ? (
        <div className="noData">No Tournaments Yet!</div>
      ) : (
        <SliderCompo
          cards={specificTypeCards}
          numOfCards={numOfCards}
          status={status}
          setDetails={setDetails}
          details={details}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export function SliderCompo({
  numOfCards,
  cards,
  status,
  setDetails,
  details,
  isLoading
}) {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: numOfCards,
    draggable: true,
    slidesToShow: details ? 2 : 3,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <div className="slider-container">
      <Slider {...settings} className="slider-tourno">
        {isLoading
          ? Array(numOfCards).fill(null).map((_, ind) => (
              <SkeletonTournamentCard key={ind} />
            ))
          : cards.map((elem, ind) => (
              <TournamentCard
                key={elem.id || ind}
                status={status}
                card={elem}
                setDetails={setDetails}
                details={details}
              />
            ))}
      </Slider>
    </div>
  );
}