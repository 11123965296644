import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Image1Web from "../../assets/images/Home/Carousels/Carousels/For Home Page/Ad Poster 1.jpg";
import Image1Mob from "../../assets/images/Home/Carousels/Carousels/For Home Page/Ad Poster 1 Mobile.jpg";
import Image2Web from "../../assets/images/Home/Carousels/Carousels/For Home Page/Ad Poster 2.jpg";
import Image2Mob from "../../assets/images/Home/Carousels/Carousels/For Home Page/Ad Poster 2 Mobile.jpg";
import Image3Web from "../../assets/images/Home/Carousels/Carousels/For Home Page/Ad Poster 3.jpg";
import Image3Mob from "../../assets/images/Home/Carousels/Carousels/For Home Page/Ad Poster 3 Mobile.jpg";
import WalletImage1 from "../../assets/images/Home/Carousels/Carousels/For wallet page/Reward Ad Poster 1.jpg";
import WalletImage1Mob from "../../assets/images/Home/Carousels/Carousels/For wallet page/Reward Ad Poster 1 Mobile.png";


const desktopImages = [
    { id: 1, url: Image1Web },
    { id: 2, url: Image2Web },
    { id: 3, url: Image3Web }
];

const mobileImages = [
    { id: 1, url: Image1Mob },
    { id: 2, url: Image2Mob },
    { id: 3, url: Image3Mob }
];
const mobileImagesWallet = [
    { id: 1, url: WalletImage1Mob },
];
const ImagesWallet = [
    { id: 1, url: WalletImage1 },
];

const Banner = ({ wallet }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    let bannerData;
    if (wallet) {
        bannerData = isMobile ? mobileImagesWallet : ImagesWallet;
    }
    else {
        bannerData = isMobile ? mobileImages : desktopImages;

    }
    return (
        <Carousel showThumbs={false} autoPlay infiniteLoop showStatus={false} showIndicators={false} className='car-home'>
            {bannerData.map((data, idx) => (
                <img
                    loading='lazy'
                    style={{ height: '30vw', minHeight: '45vh', width: '100%', objectFit: 'cover' }}
                    key={idx}
                    src={data.url}
                    alt={`banner ${data.id}`}
                />
            ))}
        </Carousel>
    );
};

export default Banner;
