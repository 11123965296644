import React, { useState, useEffect } from 'react';
import SkeletonTournamentCard from "../TournamentSection/Skeleton";
import ctcicon from '../../Component/wallet/wm-front.png';
import { useContext } from "react";
import TournamentContextProvider from "../../Context/TournamentContext";

export default function TournamentCard({ status, card, setDetails, isLoading }) {
  if (isLoading) {
    return <SkeletonTournamentCard />;
  }

  return (
    <div
      className="tournament-card"
      onClick={() => setDetails({ ...card, status })}
    >
      <CrouselCardHead card={card} />
      <CrouselCardMid status={status} card={card} />
      {status !== "Completed" && <CrouselCardFooter card={card} />}
    </div>
  );
}

export function CrouselCardHead({ card }) {
  return (
    <div className="cardHead">
      <span className="day">{card?.tournamentName}</span>
      <span className="swiss">{card?.tournamentMode}</span>
      <span className="rounds">{card?.rounds} Rounds</span>
    </div>
  );
}

function CrouselCardMid({ status, card }) {
  const barValueStr = getPercentageBarValue(card.participantsLimit, card.registrants.length);
  return (
    <div className="cardMid">
      <div className="top">
        {status !== "Completed" ? (
          <PrizePool val={card?.prizePool} />
        ) : (
          <Participants val={card?.participants} />
        )}
        <span className="timeBox">
          {status !== "Registered" && <FormattedDate val={card?.tournamentDate} />}
          <span className="time">{card?.tournamentStartTime}</span>
          {status !== "Completed" && (
            <TimeRemaining val={"10:10:10"} />
          )}
        </span>
        <RightBox status={status} card={card} />
      </div>

      {status !== "Completed" && (
        <div className="bottom">
          <div className="bar">
            <div className="value" style={{ width: barValueStr }}></div>
          </div>
          <div className="txt">
            <span className="spots-left">{card?.participantsLimit - card?.registrants.length} spots left</span>
            <span className="total-spots">{card?.participantsLimit} spots</span>
          </div>
        </div>
      )}
    </div>
  );
}

function CrouselCardFooter({ card }) {
  return (
    <div className="footer-carousel">
      Top Prize: {card?.topPrize} CTc | Win Probability: {card?.winProbability} |
      Guaranteed Rewards
    </div>
  );
}

function PrizePool({ val }) {
  return (
    <span className="prize-pool">
      <span className="textLight">Prize Pool</span>
      <span className="textBold"> 
        <div style={{display: "flex", gap: "2px", marginTop: "2px"}}>
          <img style={{width:"25px",height:"25px",marginTop:"3px"}} src={ctcicon} /> {val} 
        </div>
      </span>
    </span>
  );
}

function Participants({ val }) {
  return (
    <span className="participants">
      <span className="txt">Participants</span>
      <span className="val">{val}</span>
    </span>
  );
}

function FormattedDate({ val }) {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  return (
    <div className="date">
      <span>{formattedDate === val ? "Today" : ""}</span>
      <span>{val}</span>
    </div>
  );
}

function RightBox({ status, card }) {
  const entryFeeContent = card.paymentType === "CTC" 
    ? <div style={{display: "flex", gap: "2px", marginTop: "2px"}}>
        <img src={ctcicon} style={{width:"25px",height:"25px",marginTop:"0px"}} alt="CTC Icon" /> {card.entryFee}
      </div> 
    : `${card.entryFee} INR`;

  return (
    <span className="entry-fee">
      {status !== "Completed" && status === "Upcoming" && (
        <EntryFee val={entryFeeContent} />
      )}
      {status !== "Completed" && status === "Registered" && (
        <RightBoxDate startDate={card?.tournamentDate} startTime={card?.tournamentStartTime} />
      )}
      {status === "Completed" && <Prize val={card?.prizePool} />}
    </span>
  );
}

function TimeRemaining({ val }) {
  // Implementation left as is
}

function EntryFee({ val }) {
  return (
    <>
      <span className="textLight">Entry Fee</span>
      <span className="textBold"> {val}</span>
    </>
  );
}

function RightBoxDate({ startDate, startTime }) {
  const [countdownTime, setCountdownTime] = useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      const time = countdown(startDate, startTime);
      setCountdownTime(time);
      if (time === "00:00:00:00") {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [startDate, startTime]);

  return (
    <span className="textLightBggreen">
      <span>Countdown</span>
      <span>{countdownTime}</span>
    </span>
  );
}

function Prize({ val }) {
  return (
    <span className="wonPrize">
      <span className="youWon">Completed!</span>
      <span className="prize"></span>
    </span>
  );
}

function getPercentageBarValue(totalVal, filledVal) {
  const barValue = Math.ceil(
    Number(((Number(totalVal) - Number(filledVal)) / Number(totalVal)) * 100)
  );
  const barValueStr = barValue + "%";
  return barValueStr;
}

function countdown(startDate, startTime) {
  const targetDateString = `${startDate}T${startTime}+05:30`; // IST is UTC+5:30
  const targetDate = new Date(targetDateString);

  const now = new Date();
  const diff = targetDate - now;

  if (diff <= 0) {
    return "00:00:00:00";
  }

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return [
    days.toString().padStart(2, '0'),
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0')
  ].join(':');
}