import { FaChevronRight, FaUser } from "react-icons/fa";
import { CrouselCardHead } from "../TournamentCard/TournamentCard";

function WinnersCard({ tournament, setDetails, isSeeAllCard }) {
  let listOfWinners = tournament.winners;
  if (!isSeeAllCard) listOfWinners = [...tournament.winners].slice(0, 3);
  return (
    <div className="winnersCard">
      <CrouselCardHead card={tournament} />
      <div className="winnerList">
        {listOfWinners.map((winner, ind) => (
          <div className="row" key={winner.user_id}>
            <div className="rank">
              #{/* {ind + 1 <= 9 ? "0" : ""} */}
              {ind + 1}
            </div>
            <div className="user">
              <span className="icon">
                <FaUser />
              </span>
              <span className="user_id">{winner.user_id}</span>
            </div>
            <div className="won">₹{winner.won}</div>
          </div>
        ))}
      </div>
      {!isSeeAllCard && (
        <button className="seeAll" onClick={() => setDetails(tournament)}>
          See All <FaChevronRight />
        </button>
      )}
    </div>
  );
}

export default WinnersCard;
