import React, { useContext, useEffect, useState } from 'react';
import './NavBar.css';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../assets/images/navbar/CTcLogo1.png';
import cross from '../../assets/images/navbar/Cross.png';
import sidebar from '../../assets/images/navbar/sidebar.png';
import { AppContext } from '../../Context/Context';
import home from '../../assets/images/navbar/home.png';
import profile from '../../assets/images/navbar/profile.png';
import wallet from '../../assets/images/navbar/wallet.png';
import tournaments from '../../assets/images/navbar/tournaments.png';
import signout from '../../assets/images/navbar/signout.png';
import winners from '../../assets/images/navbar/winners.png';
import { signOutUser } from '../../utils/firebase/SignUp';

const NavBar = () => {
    const { open, setOpen, userData,  setSignIn ,isSigned } = useContext(AppContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isTablet, setIsTablet] = useState(window.innerWidth >= 768 && window.innerWidth < 1024);

    useEffect(() => {
        function handleResize() {
            const width = window.innerWidth;
            setIsMobile(width < 768);
            setIsTablet(width >= 768 && width < 1024);
            if (width > 768) {
                setOpen(false);
            }
        }

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const deAuthenticateUser = async () => {
        const res = await signOutUser();
        if (res.status === 200) {
            setSignIn(false);
        } else {
            console.error('Error in logging out!');
        }
    };

    const handleLinkClick = () => {
        if (isMobile) {
            setOpen(false);
        }
    };

    const loginLinks = [
        { tag: 'Home', path: '/' },
        { tag: 'Tournaments', path: '/tournaments' },
        { tag: 'Winners', path: '/winners' },
        { tag: 'Login/Register', path: '/login' }
    ];

    const logoutLinks = [
        { tag: 'Home', image: home, path: '/' },
        { tag: 'Tournaments', image: tournaments, path: '/tournaments' },
        { tag: 'Winners', image: winners, path: '/winners' },
        { tag: 'My Profile', image: profile, path: '/profile' },
        { tag: 'Wallet', image: wallet, path: '/wallet' },
        { tag: 'Sign Out', image: signout, path: '#' } // Sign Out does not have a path
    ];

    const links = isSigned ? logoutLinks : loginLinks.map(({ tag, path }) => ({ tag, path, image: home }));

    return (
        <div className="navBar">
            <div className='leftPart' style={open ? { backgroundColor: 'rgba(0, 0, 0, 0.7)' } : {}}>
                <img src={logo} alt='Logo' />
                <h2>Chess Tycoon</h2>
                <img
                    src={open ? cross : sidebar}
                    style={open ? { cursor: 'pointer' } : { cursor: 'pointer', width: 40, height: 32 }}
                    className={!isMobile ? 'hidden' : ''}
                    alt={open ? 'close menu' : 'open menu'}
                    onClick={() => setOpen(prev => !prev)}
                />
            </div>
            <div className={!isMobile ? "rightPart" : `linksSideBar ${open ? "open" : ""}`}>
                {
                    links.map((link, index) => (
                        <div key={index} style={{ display: 'flex', gap: '15px', minWidth: open ? '230px' : '', justifyContent: isSigned ? 'left' : 'center' }}>
                            {isMobile && <img src={link.image} style={{ width: '30px' }} className={isSigned ? '' : 'hidden'} alt='logoOfLink' />}
                            {link.tag === "Sign Out" ? (
                                <Link onClick={() => { deAuthenticateUser(); handleLinkClick(); }} className='link'>{link.tag}</Link>
                            ) : (
                                <NavLink
                                    to={link.path}
                                    className={({ isActive }) => isActive ? "active-link" : "link"}
                                    onClick={handleLinkClick}
                                >
                                    {link.tag}
                                </NavLink>
                            )}
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default NavBar;
