import { useEffect, useLayoutEffect, useState } from "react";

import WebWinners from "../../Component/WebWinners/WebWinners";
import MobileWinners from "../../Component/MobileWinners/MobileWinners";
import "../../tournament_Winners.css";

// dummy data
const types = ["bullet", "blitz", "rapid", "classical"];
const carouselItemsArr = [
  {
    type: "bullet",
    tournaments: [
      {
        frequency: "Tuesday Weekly",
        rounds: 7,
        winners: [
          { user_id: "ctc_user_123456", won: 2300 },
          { user_id: "ctc_user_234567", won: 2200 },
          { user_id: "ctc_user_345678", won: 2100 },
          { user_id: "ctc_user_456789", won: 2000 },
          { user_id: "ctc_user_567890", won: 1900 },
          { user_id: "ctc_user_678901", won: 1800 },
          { user_id: "ctc_user_789012", won: 1700 },
          { user_id: "ctc_user_890123", won: 1600 },
          { user_id: "ctc_user_901234", won: 1500 },
          { user_id: "ctc_user_012345", won: 1400 },
        ],
      },
      {
        frequency: "Thursday Weekly",
        rounds: 5,
        winners: [
          { user_id: "ctc_user_135790", won: 2000 },
          { user_id: "ctc_user_246801", won: 1900 },
          { user_id: "ctc_user_357912", won: 1800 },
          { user_id: "ctc_user_468023", won: 1700 },
          { user_id: "ctc_user_579134", won: 1600 },
          { user_id: "ctc_user_680245", won: 1500 },
          { user_id: "ctc_user_791356", won: 1400 },
          { user_id: "ctc_user_802467", won: 1300 },
          { user_id: "ctc_user_913578", won: 1200 },
          { user_id: "ctc_user_024689", won: 1100 },
        ],
      },
      {
        frequency: "Saturday Weekly",
        rounds: 6,
        winners: [
          { user_id: "ctc_user_147258", won: 2500 },
          { user_id: "ctc_user_258369", won: 2400 },
          { user_id: "ctc_user_369470", won: 2300 },
          { user_id: "ctc_user_470581", won: 2200 },
          { user_id: "ctc_user_581692", won: 2100 },
          { user_id: "ctc_user_692703", won: 2000 },
          { user_id: "ctc_user_703814", won: 1900 },
          { user_id: "ctc_user_814925", won: 1800 },
          { user_id: "ctc_user_925036", won: 1700 },
          { user_id: "ctc_user_036147", won: 1600 },
        ],
      },
      {
        frequency: "Monthly",
        rounds: 8,
        winners: [
          { user_id: "ctc_user_159753", won: 3000 },
          { user_id: "ctc_user_260864", won: 2900 },
          { user_id: "ctc_user_371975", won: 2800 },
          { user_id: "ctc_user_482086", won: 2700 },
          { user_id: "ctc_user_593197", won: 2600 },
          { user_id: "ctc_user_604208", won: 2500 },
          { user_id: "ctc_user_715319", won: 2400 },
          { user_id: "ctc_user_826420", won: 2300 },
          { user_id: "ctc_user_937531", won: 2200 },
          { user_id: "ctc_user_048642", won: 2100 },
        ],
      },
      {
        frequency: "Quarterly",
        rounds: 10,
        winners: [
          { user_id: "ctc_user_951753", won: 5000 },
          { user_id: "ctc_user_862864", won: 4800 },
          { user_id: "ctc_user_773975", won: 4600 },
          { user_id: "ctc_user_684086", won: 4400 },
          { user_id: "ctc_user_595197", won: 4200 },
          { user_id: "ctc_user_406208", won: 4000 },
          { user_id: "ctc_user_317319", won: 3800 },
          { user_id: "ctc_user_228420", won: 3600 },
          { user_id: "ctc_user_139531", won: 3400 },
          { user_id: "ctc_user_040642", won: 3200 },
        ],
      },
    ],
  },
  {
    type: "blitz",
    tournaments: [
      {
        frequency: "Monday Weekly",
        rounds: 6,
        winners: [
          { user_id: "ctc_user_111222", won: 2800 },
          { user_id: "ctc_user_222333", won: 2700 },
          { user_id: "ctc_user_333444", won: 2600 },
          { user_id: "ctc_user_444555", won: 2500 },
          { user_id: "ctc_user_555666", won: 2400 },
          { user_id: "ctc_user_666777", won: 2300 },
          { user_id: "ctc_user_777888", won: 2200 },
          { user_id: "ctc_user_888999", won: 2100 },
          { user_id: "ctc_user_999000", won: 2000 },
          { user_id: "ctc_user_000111", won: 1900 },
        ],
      },
      {
        frequency: "Wednesday Weekly",
        rounds: 5,
        winners: [
          { user_id: "ctc_user_121212", won: 2500 },
          { user_id: "ctc_user_232323", won: 2400 },
          { user_id: "ctc_user_343434", won: 2300 },
          { user_id: "ctc_user_454545", won: 2200 },
          { user_id: "ctc_user_565656", won: 2100 },
          { user_id: "ctc_user_676767", won: 2000 },
          { user_id: "ctc_user_787878", won: 1900 },
          { user_id: "ctc_user_898989", won: 1800 },
          { user_id: "ctc_user_909090", won: 1700 },
          { user_id: "ctc_user_010101", won: 1600 },
        ],
      },
      {
        frequency: "Friday Weekly",
        rounds: 7,
        winners: [
          { user_id: "ctc_user_131313", won: 3000 },
          { user_id: "ctc_user_242424", won: 2900 },
          { user_id: "ctc_user_353535", won: 2800 },
          { user_id: "ctc_user_464646", won: 2700 },
          { user_id: "ctc_user_575757", won: 2600 },
          { user_id: "ctc_user_686868", won: 2500 },
          { user_id: "ctc_user_797979", won: 2400 },
          { user_id: "ctc_user_808080", won: 2300 },
          { user_id: "ctc_user_919191", won: 2200 },
          { user_id: "ctc_user_020202", won: 2100 },
        ],
      },
      {
        frequency: "Bi-Weekly",
        rounds: 8,
        winners: [
          { user_id: "ctc_user_141414", won: 3500 },
          { user_id: "ctc_user_252525", won: 3400 },
          { user_id: "ctc_user_363636", won: 3300 },
          { user_id: "ctc_user_474747", won: 3200 },
          { user_id: "ctc_user_585858", won: 3100 },
          { user_id: "ctc_user_696969", won: 3000 },
          { user_id: "ctc_user_707070", won: 2900 },
          { user_id: "ctc_user_818181", won: 2800 },
          { user_id: "ctc_user_929292", won: 2700 },
          { user_id: "ctc_user_030303", won: 2600 },
        ],
      },
      {
        frequency: "Monthly",
        rounds: 9,
        winners: [
          { user_id: "ctc_user_151515", won: 4000 },
          { user_id: "ctc_user_262626", won: 3900 },
          { user_id: "ctc_user_373737", won: 3800 },
          { user_id: "ctc_user_484848", won: 3700 },
          { user_id: "ctc_user_595959", won: 3600 },
          { user_id: "ctc_user_606060", won: 3500 },
          { user_id: "ctc_user_717171", won: 3400 },
          { user_id: "ctc_user_828282", won: 3300 },
          { user_id: "ctc_user_939393", won: 3200 },
          { user_id: "ctc_user_040404", won: 3100 },
        ],
      },
    ],
  },
  {
    type: "rapid",
    tournaments: [
      {
        frequency: "Sunday Weekly",
        rounds: 5,
        winners: [
          { user_id: "ctc_user_161616", won: 3200 },
          { user_id: "ctc_user_272727", won: 3100 },
          { user_id: "ctc_user_383838", won: 3000 },
          { user_id: "ctc_user_494949", won: 2900 },
          { user_id: "ctc_user_505050", won: 2800 },
          { user_id: "ctc_user_616161", won: 2700 },
          { user_id: "ctc_user_727272", won: 2600 },
          { user_id: "ctc_user_838383", won: 2500 },
          { user_id: "ctc_user_949494", won: 2400 },
          { user_id: "ctc_user_050505", won: 2300 },
        ],
      },
      {
        frequency: "Tuesday Weekly",
        rounds: 6,
        winners: [
          { user_id: "ctc_user_171717", won: 3500 },
          { user_id: "ctc_user_282828", won: 3400 },
          { user_id: "ctc_user_393939", won: 3300 },
          { user_id: "ctc_user_404040", won: 3200 },
          { user_id: "ctc_user_515151", won: 3100 },
          { user_id: "ctc_user_626262", won: 3000 },
          { user_id: "ctc_user_737373", won: 2900 },
          { user_id: "ctc_user_848484", won: 2800 },
          { user_id: "ctc_user_959595", won: 2700 },
          { user_id: "ctc_user_060606", won: 2600 },
        ],
      },
      {
        frequency: "Thursday Weekly",
        rounds: 7,
        winners: [
          { user_id: "ctc_user_181818", won: 3800 },
          { user_id: "ctc_user_292929", won: 3700 },
          { user_id: "ctc_user_303030", won: 3600 },
          { user_id: "ctc_user_414141", won: 3500 },
          { user_id: "ctc_user_525252", won: 3400 },
          { user_id: "ctc_user_636363", won: 3300 },
          { user_id: "ctc_user_747474", won: 3200 },
          { user_id: "ctc_user_858585", won: 3100 },
          { user_id: "ctc_user_969696", won: 3000 },
          { user_id: "ctc_user_070707", won: 2900 },
        ],
      },
      {
        frequency: "Bi-Weekly",
        rounds: 8,
        winners: [
          { user_id: "ctc_user_191919", won: 4200 },
          { user_id: "ctc_user_202020", won: 4100 },
          { user_id: "ctc_user_313131", won: 4000 },
          { user_id: "ctc_user_424242", won: 3900 },
          { user_id: "ctc_user_535353", won: 3800 },
          { user_id: "ctc_user_646464", won: 3700 },
          { user_id: "ctc_user_757575", won: 3600 },
          { user_id: "ctc_user_868686", won: 3500 },
          { user_id: "ctc_user_979797", won: 3400 },
          { user_id: "ctc_user_080808", won: 3300 },
        ],
      },
      {
        frequency: "Monthly",
        rounds: 9,
        winners: [
          { user_id: "ctc_user_212121", won: 5000 },
          { user_id: "ctc_user_323232", won: 4800 },
          { user_id: "ctc_user_434343", won: 4600 },
          { user_id: "ctc_user_545454", won: 4400 },
          { user_id: "ctc_user_656565", won: 4200 },
          { user_id: "ctc_user_767676", won: 4000 },
          { user_id: "ctc_user_878787", won: 3800 },
          { user_id: "ctc_user_989898", won: 3600 },
          { user_id: "ctc_user_090909", won: 3400 },
          { user_id: "ctc_user_101010", won: 3200 },
        ],
      },
    ],
  },
  {
    type: "classical",
    tournaments: [
      {
        frequency: "Weekly",
        rounds: 7,
        winners: [
          { user_id: "ctc_user_222222", won: 5500 },
          { user_id: "ctc_user_333333", won: 5300 },
          { user_id: "ctc_user_444444", won: 5100 },
          { user_id: "ctc_user_555555", won: 4900 },
          { user_id: "ctc_user_666666", won: 4700 },
          { user_id: "ctc_user_777777", won: 4500 },
          { user_id: "ctc_user_888888", won: 4300 },
          { user_id: "ctc_user_999999", won: 4100 },
          { user_id: "ctc_user_000000", won: 3900 },
          { user_id: "ctc_user_111111", won: 3700 },
        ],
      },
      {
        frequency: "Bi-Weekly",
        rounds: 8,
        winners: [
          { user_id: "ctc_user_121212", won: 6000 },
          { user_id: "ctc_user_232323", won: 5800 },
          { user_id: "ctc_user_343434", won: 5600 },
          { user_id: "ctc_user_454545", won: 5400 },
          { user_id: "ctc_user_565656", won: 5200 },
          { user_id: "ctc_user_676767", won: 5000 },
          { user_id: "ctc_user_787878", won: 4800 },
          { user_id: "ctc_user_898989", won: 4600 },
          { user_id: "ctc_user_909090", won: 4400 },
          { user_id: "ctc_user_010101", won: 4200 },
        ],
      },
      {
        frequency: "Monthly",
        rounds: 9,
        winners: [
          { user_id: "ctc_user_131313", won: 7000 },
          { user_id: "ctc_user_242424", won: 6700 },
          { user_id: "ctc_user_353535", won: 6400 },
          { user_id: "ctc_user_464646", won: 6100 },
          { user_id: "ctc_user_575757", won: 5800 },
          { user_id: "ctc_user_686868", won: 5500 },
          { user_id: "ctc_user_797979", won: 5200 },
          { user_id: "ctc_user_808080", won: 4900 },
          { user_id: "ctc_user_919191", won: 4600 },
          { user_id: "ctc_user_020202", won: 4300 },
        ],
      },
      {
        frequency: "Quarterly",
        rounds: 10,
        winners: [
          { user_id: "ctc_user_141414", won: 8000 },
          { user_id: "ctc_user_252525", won: 7700 },
          { user_id: "ctc_user_363636", won: 7400 },
          { user_id: "ctc_user_474747", won: 7100 },
          { user_id: "ctc_user_585858", won: 6800 },
          { user_id: "ctc_user_696969", won: 6500 },
          { user_id: "ctc_user_707070", won: 6200 },
          { user_id: "ctc_user_818181", won: 5900 },
          { user_id: "ctc_user_929292", won: 5600 },
          { user_id: "ctc_user_030303", won: 5300 },
        ],
      },
      {
        frequency: "Yearly",
        rounds: 12,
        winners: [
          { user_id: "ctc_user_151515", won: 10000 },
          { user_id: "ctc_user_262626", won: 9500 },
          { user_id: "ctc_user_373737", won: 9000 },
          { user_id: "ctc_user_484848", won: 8500 },
          { user_id: "ctc_user_595959", won: 8000 },
          { user_id: "ctc_user_606060", won: 7500 },
          { user_id: "ctc_user_717171", won: 7000 },
          { user_id: "ctc_user_828282", won: 6500 },
          { user_id: "ctc_user_939393", won: 6000 },
          { user_id: "ctc_user_040404", won: 5500 },
        ],
      },
    ],
  },
];

// const carouselItemsArr = [
//   {
//     type: "bullet",
//     tournaments: [],
//   },
//   {
//     type: "blitz",
//     tournaments: [],
//   },
//   {
//     type: "rapid",
//     tournaments: [],
//   },
//   {
//     type: "classical",
//     tournaments: [],
//   },
// ];


export default function Winners() {
  const [details, setDetails] = useState(null);
  const [numOfCards, setNumOfCards] = useState(1);
  const [width] = useWindowSize();
  const [type, setType] = useState("bullet");

  //   num of cards
  useEffect(
    function () {
      if (details) return;
      if (width >= 1680) setNumOfCards(4);
      else if (width >= 1250) setNumOfCards(3);
      else if (width >= 880) setNumOfCards(2);
      else setNumOfCards(2);
    },
    [width, details]
  );
  //   num of cards when details is open
  useEffect(
    function () {
      if (!details) return;
      if (width >= 1680) setNumOfCards(2);
      else setNumOfCards(1);
    },
    [details, width]
  );
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  if (width <= 880)
    return (
      <MobileWinners
        type={type}
        types={types}
        setType={setType}
        setDetails={setDetails}
        details={details}
        width={width}
        carouselItemsArr={carouselItemsArr}
      />
    );
  return (
    <WebWinners
      width={width}
      setDetails={setDetails}
      details={details}
      numOfCards={numOfCards}
      carouselItemsArr={carouselItemsArr}
    />
  );
}
