import React, { useContext, useEffect, useRef, useState } from "react";
import Chess from "../../assets/images/Home/ChessBoard.png";
import "./HomeContent.css";
import Banner from "../Banner/Banner";
import { motion, useInView, useMotionValue, useScroll, useTransform } from "framer-motion";
import Reveal from "../Reveal/Reveal";
import {AppContext} from "../../Context/Context";

const HomeContent = () => {
    const {isSigned, setSignIn} = useContext(AppContext);
    // const [ isSigned, setSignIn] =  useState(false)
  return (
    <>
    <Banner />
      <div  className="container">
      <Reveal>
        <div  className="row1">
          <img src={Chess} alt="chessBoard" />
          <div className="row1-col1">
            <p className="heading1">
              Convert Your Passion<span style={{ color: "red" }}></span> {" "}
              <span style={{ color: "#779954" }}>into ₹Money</span>
            </p>
            <p className="heading2Container">
              <p className="heading2">
                Register into{" "}
                <span style={{ fontWeight: 600 }}>Chess Tournaments</span> and
                Win Big!
              </p>
            </p>
            <p className="heading3">
              <a href={isSigned ? "/tournaments" : '/login'} style={{ color: "#779954" }}>
                Register Now
              </a>{" "}
              and <span style={{ color: "maroon" }}>Win up to 11x</span> your
              Registration Fee
            </p>
          </div>
        </div>
      </Reveal>
      <Reveal>
        <p   className="row2">
          Chess Tycoon "CTc" is a platform for all the passionate Chess Lovers
          who want to earn real money by playing Chess Tournaments. We conduct
          daily Chess Tournaments in different formats such as{" "}
          <span style={{ color: "#779954" }}>
            Ultrabullet, Bullet, Blitz, Rapid, Classical,{" "}
          </span>{" "}
          and <span style={{ color: "#779954" }}>Chess960</span> providing{" "}
          <span style={{ color: "#779954" }}>Cash Prizes </span>to the winners
          and <span style={{ color: "#779954" }}>CTc Coins </span>to everyone
          which can be redeemed for exclusive rewards.
        </p>
      </Reveal>
      <Reveal>
        <div  className="row3">
          <h2>
            Steps to <span style={{ color: "#779954" }}>#Register</span>
          </h2>
          <p className="boxBorder" />
          <div className="registerProcess">
            <div>
              <h3>
                <span style={{ color: "#779954" }}>Step 1: </span>Create your
                Account
              </h3>
              <p>
                Sign Up using your basic details and Lichess ID from{" "}
                <a href={isSigned ? '/tournaments' : '/login'}>here</a>
              </p>
            </div>
            <div>
              <h3>
                <span style={{ color: "#779954" }}>Step 2: </span>Select
                Tournament
              </h3>
              <p>
                Select your Tournament based on your Preference and Rating range
                from the dashboard
              </p>
            </div>
            <div>
              <h3>
                <span style={{ color: "#779954" }}>Step 3: </span>Join the Game
              </h3>
              <p>
                Review your selected tournament, and pay the respective
                Registration Fee. That’s it.
              </p>
            </div>
          </div>
          <p className="boxBorder" />
          <h3 className="chance">The Chance is Now!</h3>
        </div>
      </Reveal>
      <Reveal>
        <div className="row4">
          <h1>
            Why do we <span style={{ color: "#779954" }}>#exist</span>?
          </h1>
          <p className="boxBorder" />
          <p className="row2">
            Chess is a popular game worldwide, yet only the top players are able
            to earn money from it. Chess Tycoon aims to change that by providing
            opportunities for all chess enthusiasts to showcase their skills and
            win cash prizes.
          </p>
          <p className="row2">
            Offline tournaments are rare in many cities, and it's challenging to
            organize them daily. This leaves many chess talents untapped. Chess
            Tycoon solves this problem by hosting exciting daily tournaments,
            allowing chess lovers to pursue their passion and earn money
            simultaneously.
          </p>
          <p className="row2">
            Traveling to different cities for tournaments can be expensive due
            to costs for food, accommodation, and transportation. Chess Tycoon
            offers tournaments with low risk and high rewards, eliminating these
            expenses.
          </p>
          <p className="row2">
            We have also introduced chess coins, which can be used to buy
            exciting rewards and discounted coupons.
          </p>
        </div>
      </Reveal>
      <Reveal>
        <div className="row5">
          <div className="row4">
            <h1>
              Our <span style={{ color: "#779954" }}>#Mission</span>
            </h1>
            <p className="boxBorder" />
            <p className="row2">
              Our mission is to provide chess players with regular opportunities
              to play in tournaments and win real money. We recognize chess as a
              skill-intensive game and aim to reward these skills through our
              tournaments.
            </p>
          </div>
          <div className="row4">
            <h1>
              Our <span style={{ color: "#779954" }}>#Vision</span>
            </h1>
            <p className="boxBorder" />
            <p className="row2">
              Our vision is to promote chess as a valuable skill that can
              generate regular income. We strive to popularize chess so that
              everyone can see it as a potential earning source.
            </p>
          </div>
        </div>
      </Reveal>
      </div>
    </>
  );
};

export default HomeContent;
