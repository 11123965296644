import React, { useContext, useEffect, useState } from "react";
import NavBar from "../../Component/NavBar/NavBar";
import userImage from "../../assets/images/Profile/profile.jpg";
import './Profile.css';
import flag from '../../assets/images/Profile/IN.png';
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { AppContext } from "../../Context/Context";
import { Loader2 } from "lucide-react";
import { updateProfilePicture } from "../../utils/functions/updateProfilePicture";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../../Component/Footer/Footer";

const Profile = () => {
  const [firetoken, setFiretoken] = useState("");
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const {  getUser, idtokencontex, setidtokencontex } = useContext(AppContext);
  const { userData, setUserData } = useContext(AppContext);
  const [userDetails, setUserDetails] = useState({
    fname: "",
    lname: "",
    email: "",
    lichessId: "",
    line: "",
    city: "",
    pincode: "",
    mobile: "",
    dob: "",
    gender: "",
    bio: "",
    country: "",
    state: ""
  });
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  useEffect(() => {
    if (userData?.name) {
      const name1 = userData.name.split(" ");
      setUserDetails({
        fname: name1[0],
        lname: name1[1] || "",
        bio: userData.bio || "",
        email: userData.email || "",
        lichessId: userData.lichessId || "",
        line: userData.line || "",
        city: userData.city || "",
        dob: userData.dob || "",
        pincode: userData.pincode || "",
        gender: userData.gender || "",
        mobile: userData.mobile || "",
        country: userData.country || "",
        state: userData.state || ""
      });
      setCountry(userData.country || "");
      setState(userData.state || "");
    }
  }, [userData]); // Only depend on userData

  // Second useEffect for Firebase token
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      return;
    }

    const fetchUserData = async () => {
      try {
        const idToken = await user.getIdToken(true);
        setFiretoken(idToken);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [idtokencontex]);
  const onProfileValueChange = (event) => {
    const { name, value } = event.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handlePhotoChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('profilePicture', userData.user.profilePicture || null);

    try {
      const response = await updateProfilePicture(formData);
      if (response.status === 200) {
        toast.success('Profile picture updated successfully!');
        const updatedUser = await getUser(idtokencontex);
        const profilePicture = updatedUser.profilePicture;
      }
    } catch (error) {
      console.error('Error updating profile picture:', error);
      toast.error('Failed to update profile picture. Please try again.');
      setPreviewUrl(null);
    } finally {
      setIsUploading(false);
    }
  };

  const handleProfileEdit = async (event) => {
    event.preventDefault();

    const updatedUserDetails = {
      ...userDetails,
      country,
      state
    };

    try {
      const res = await axios.put('https://us-central1-chesstycoon.cloudfunctions.net/app/api/v1/updateUser', updatedUserDetails, {
        headers: {
          'Authorization': `Bearer ${firetoken}`,
          'Content-Type': 'application/json'
        }
      });
      toast.success('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile. Please try again.');
    }
  };

  return (
    <>
      <NavBar />
      <form className="profileForm" onSubmit={handleProfileEdit}>
        <div className="editProfile">
          <button type="submit" className="editButton">Edit Profile</button>
        </div>
        <div className="userNameDetails">
          <div className="userNameImage">
            <div className="imageContainer">
              {isUploading ? (
                <div className="loaderOverlay">
                  <Loader2 className="animate-spin" />
                </div>
              ) : (
                <img className="userImage" src={userImage} alt="userImage" />
              )}
            </div>
            <div className="userName">
              <p>CTC Id</p>
              <p>{userData?.user?.ctcId}</p>
            </div>
          </div>
          <div className="photo-uploader">
            <input
              type="file"
              accept="image/*"
              onChange={handlePhotoChange}
              id="photo-upload"
              style={{ display: 'none' }}
            />
          </div>
        </div>
        <div className="formFields">
          <div className="leftForm">
            <label>
              First Name
              <input
                required
                value={userDetails.fname}
                onChange={onProfileValueChange}
                name="fname"
                type="text"
                placeholder="First Name"
              />
            </label>
            <label>
              Last Name
              <input
                required
                value={userDetails.lname}
                onChange={onProfileValueChange}
                name="lname"
                type="text"
                placeholder="Last Name"
              />
            </label>
            <label>
              Email
              <input
                required
                value={userDetails.email}
                disabled={true}
                onChange={onProfileValueChange}
                name="email"
                type="email"
                placeholder="Email"
              />
            </label>
            <label>
              Lichess ID
              <input
                required
                value={userDetails.lichessId}
                onChange={onProfileValueChange}
                name="lichessId"
                disabled={true}
                type="text"
                placeholder="Lichess ID"
              />
            </label>
            <label className="address">
              Address
              <input
                required
                value={userDetails.line}
                onChange={onProfileValueChange}
                name="line"
                type="text"
                placeholder="Address Line"
              />
              <input
                required
                value={userDetails.city}
                onChange={onProfileValueChange}
                name="city"
                type="text"
                placeholder="City"
              />
              <input
                required
                value={userDetails.pincode}
                onChange={onProfileValueChange}
                name="pincode"
                type="text"
                placeholder="Pincode"
              />
              <CountryDropdown
                value={country}
                onChange={(val) => {
                  setCountry(val);
                  setUserDetails(prev => ({ ...prev, country: val }));
                }} />
              <RegionDropdown
                country={country}
                value={state}
                onChange={(val) => {
                  setState(val);
                  setUserDetails(prev => ({ ...prev, state: val }));
                }} />
            </label>
          </div>
          <div className="rightForm">
            <label>
              Mobile Number
              <img className="flag" src={flag} alt="IndianFlag" />
              <input
                required
                value={userDetails.mobile}
                onChange={onProfileValueChange}
                name="mobile"
                type="tel"
                placeholder="Mobile Number"
              />
            </label>
            <label htmlFor="dob">
              Date of Birth:
              <input
                type="date"
                id="dob"
                name="dob"
                value={userDetails.dob}
                onChange={onProfileValueChange}
                required
              />
            </label>
            <label htmlFor="gender">
              Gender:
              <select
                id="gender"
                name="gender"
                value={userDetails.gender}
                onChange={onProfileValueChange}
                required
              >
                <option value="">Select gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </label>
            <label htmlFor="bio">
              Bio:
              <textarea
                id="bio"
                name="bio"
                value={userDetails.bio}
                onChange={onProfileValueChange}
                rows="4"
              />
            </label>
          </div>
        </div>
      </form>
      <Footer/>
      <ToastContainer />
    </>
  );
};

export default Profile;
