import { RxCross1 } from "react-icons/rx";
import WinnersCard from "../WinnersCard/WinnersCard";

export default function WinnersCardDetails({ setDetails, details }) {
  return (
    <div className="detailsOfCard winnersDetailsOfCard">
      <div className="back">
        <button onClick={() => setDetails(null)}>
          <RxCross1 />
        </button>
      </div>
      <WinnersCard tournament={details} isSeeAllCard={true} />
    </div>
  );
}
