import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import styles from '../assets/styles/adminsignin.module.css';
import backgroundImage from '../assets/images/b13b55baf46a2e6488bc3b0f3542c3a9.png'; // Assuming you have a background image
import { auth } from '../utils/firebase/Firebase';
import logo from '../assets/images/navbar/CTcLogo1.png'
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";

function AdminSignup(){
  const navigate = useNavigate();
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mobile, setMobile] = useState('');
  const [showPassword , setShowPassword] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log(userCredential.user);
      navigate('/adminlogin');
    } catch (error) {
      console.error(error.code, error.message);
    }
  };

  return (
    <>
    
    <div className={styles.container} style={{ backgroundImage: `url(${backgroundImage})` }}>
       <div className={styles.navb}><img className={styles.logo} src={logo} alt="logo" /> <div className={styles.logotext}>Chess Tycoon</div> </div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <h2>Sign Up</h2>
        
        <input
        placeholder='Email'
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        
        <input
          type="tel"
          placeholder='Mobile no.'
          id="mobile"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          required
        />
        
        {/* <input
          type="password"
          id="password"
          placeholder='Password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          
        />
       <div style={{display : "inline-block" , position: 'relative' , height : "100%" , right : "20px"}}> <FaEye/></div>
         */}
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center',  width : "95%" ,marginBottom : "12px" }}>
      <input
        type={showPassword ? 'text' : 'password'}
        id="password"
        placeholder='Password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        style={{ paddingRight: '40px', flex: 1  , margin : "0"}}
      />
      <div 
        onClick={() => setShowPassword(!showPassword)} 
        style={{ 
          position: 'absolute', 
          right: '10px', 
          cursor: 'pointer',
          height : "100%",
          display : "flex",
          justifyContent : "center",
          alignItems : "center"
        }}
      >
       {!showPassword ? <FaEye /> : <FaEyeSlash/>  } 
      </div>
    </div>

        <button type="submit" className={styles.submitButton}>Submit</button>
        <Link className={styles.link} to="/adminlogin">Already have an account</Link> 
      </form>
    </div>
    </>
  );
}

export default AdminSignup;
