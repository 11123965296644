import React, { useState, useEffect } from 'react'
import './App.css'
import { ReactComponent as Logo } from './CTc Logo.svg';
import X from "./x.png"
import Insta from "./insta.png"
import Linkedin from "./linkedin.png"
import axios from 'axios';

const App = () => {
  const [text, setText] = useState('');
  const fullText = 'We Are Coming Soon!';
  const [timeLeft, setTimeLeft] = useState({ days: '--', hours: '--', minutes: '--', seconds: '--' });
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [lichessUsername, setLichessUsername] = useState('');
  const [submitStatus, setSubmitStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Hardcoded release date (YYYY-MM-DD format)
  const releaseDate = '2024-07-29T14:30:00.000Z';


  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      setText(fullText.slice(0, index));
      index++;
      if (index > fullText.length) {
        clearInterval(intervalId);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const distance = new Date(releaseDate).getTime() - now;

      if (distance < 0) {
        setTimeLeft({ days: '00', hours: '00', minutes: '00', seconds: '00' });
        setLoading(false);
      } else {
        setTimeLeft({
          days: Math.floor(distance / (1000 * 60 * 60 * 24)).toString().padStart(2, '0'),
          hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0'),
          minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0'),
          seconds: Math.floor((distance % (1000 * 60)) / 1000).toString().padStart(2, '0'),
        });
      }
    };

    calculateTimeLeft(); // Initial calculation

    const timer = setInterval(calculateTimeLeft, 1000);

    // Simulate loading for 2 seconds
    setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent double submission
    setIsSubmitting(true);
    setSubmitStatus('Submitting...');
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/v1/earlySignUp`, { email, lichessUsername });
      
      if (response.status === 201) {
        setSubmitStatus('Thank you for joining!');
        setEmail('');
        setLichessUsername('');
      } else if(response.status === 200) {
        setEmail('');
        setLichessUsername('');
        setSubmitStatus('You are already registered. Thank you!');
      }
       else {
        setSubmitStatus('Error. Please try again.');
      }
    } catch (error) {
      setSubmitStatus('Error. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  
    // Clear status after 3 seconds
    setTimeout(() => setSubmitStatus(''), 3000);
  };

  return (
    <div className='bg'>
      <div className="content-wrapper">
        <div className="heading">
          <Logo className='logo reveal-element' />
          <div className="text reveal-element">Chess Tycoon</div>
        </div>
        <div className="timer-section">
          {/* <div className="timer">
            {['days', 'hours', 'minutes', 'seconds'].map((unit) => (
              <div key={unit} className="time-block">
                <span className="time">{loading ? '--' : timeLeft[unit]}</span>
                <span className="label">{unit.charAt(0).toUpperCase() + unit.slice(1)}</span>
              </div>
            ))}
          </div> */}
        </div>
        <div className="center">
          {text}
        </div>
        <div className="read-more">
          <a href="https://www.linkedin.com/pulse/why-do-we-exist-chess-tycoon-hskyf/?trackingId=CrF%2FX15DmYMmquMcnOrwDA%3D%3D" target="_blank" rel="noopener noreferrer">Read this article to know more about us</a>
        </div>
        
        {/* <div className="email-signup">
          <h2>Pre-register now, and we'll notify you as soon as we go live! Stay tuned for exciting rewards!</h2>
          <form onSubmit={handleSubmit}>
            <input 
              type="email" 
              placeholder="Enter your Email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input 
              type="text" 
              placeholder="Lichess Username" 
              value={lichessUsername} 
              onChange={(e) => setLichessUsername(e.target.value)}
            />
            <button type="submit" disabled={isSubmitting}>Join Now</button>
          </form>
          {submitStatus && <p className={`submit-status ${submitStatus ? 'visible' : ''}`}>{submitStatus}</p>}
        </div> */}
      </div>
      
      <div className="footer">
        <div className="footer-text">
          About | Terms & Conditions | Refund Policy | Privacy Policy | Chess Tycoon © 2024 | <a href="https://www.zorwaytech.com" target="_blank" rel="noopener noreferrer">A Product of Zorway Technologies LLP</a>
        </div>
        <div className="social">
          <a href="https://x.com/chesstycoon_in" target="_blank" rel="noopener noreferrer">
            <img src={X} alt="Twitter" />
          </a>
          <a href="https://www.instagram.com/chesstycoonofficial/" target="_blank" rel="noopener noreferrer">
            <img src={Insta} alt="Instagram" />
          </a>
          <a href="https://www.linkedin.com/showcase/chess-tycoon/" target="_blank" rel="noopener noreferrer">
            <img src={Linkedin} alt="LinkedIn" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default App