import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import axiosCTC from '../axiosClientCTC';

const TournamentContext = createContext();

export const useTournament = () => useContext(TournamentContext);

export const TournamentContextProvider = ({ children }) => {
  const [tournaments, setTournaments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
 
  
  // Fetch tournaments data
  const fetchTournaments = useCallback(async (page = currentPage, pageLimit = limit) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axiosCTC.get(`/getTournaments?page=${page}&limit=${pageLimit}`);
      setTournaments(response.data.data);
      // console.log(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(page);
      setLimit(pageLimit);
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while fetching tournaments');
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, limit]);
 
  // Go to the next page
  const nextPage = useCallback(() => {
    if (currentPage < totalPages) {
      fetchTournaments(currentPage + 1);
    }
  }, [currentPage, totalPages, fetchTournaments]);

  // Go to the previous page
  const prevPage = useCallback(() => {
    if (currentPage > 1) {
      fetchTournaments(currentPage - 1);
    }
  }, [currentPage, fetchTournaments]);

  // Go to a specific page
  const goToPage = useCallback((page) => {
    if (page >= 1 && page <= totalPages) {
      fetchTournaments(page);
    }
  }, [totalPages, fetchTournaments]);

  // Change the limit of items per page
  const changeLimit = useCallback((newLimit) => {
    setLimit(newLimit);
    fetchTournaments(1, newLimit);
  }, [fetchTournaments]);

  

  const value = {
    tournaments,
    currentPage,
    limit,
    totalPages,
    isLoading,
    error,
    fetchTournaments,
    nextPage,
    prevPage,
    goToPage,
    changeLimit
  };
  useEffect(() => {
    fetchTournaments();
  }, [fetchTournaments]);

  return (
    <TournamentContext.Provider value={value}>
      {children}
    </TournamentContext.Provider>
  );
};

export default TournamentContextProvider;
