import { useState } from "react";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";
import WinnersCardDetails from "../WinnersCardDetails/WinnersCardDetails";
import WinnersSection from "../WinnersSection/WinnersSection";

export default function WebWinners({
  width,
  setDetails,
  details,
  numOfCards,
  carouselItemsArr,
}) {
  const [isViewAllActive, setIsViewAllActive] = useState("");
  return (
    <>
      <NavBar />
      <div className="tournament">
        <div
          className="left"
          style={details && width <= 1280 ? { width: 0, height: 0 } : {}}
        >
          {carouselItemsArr.map((item, ind) => (
            <WinnersSection
              key={item.type}
              item={item}
              width={width}
              setDetails={setDetails}
              details={details}
              numOfCards={numOfCards}
              isViewAllActive={isViewAllActive}
              setIsViewAllActive={setIsViewAllActive}
            />
          ))}
        </div>
        {details && (
          <WinnersCardDetails
            setDetails={setDetails}
            width={width}
            details={details}
          />
        )}
      </div>
      <Footer />
    </>
  );
}
