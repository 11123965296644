import { useContext, useEffect, useState } from "react";
import CardDetails from "../CardDetails/CardDetails";
import DropDown from "../DropDown/DropDown";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";
import TournamentCard from "../TournamentCard/TournamentCard";
import SkeletonTournamentCard from "../TournamentSection/Skeleton";
import { AppContext } from "../../Context/Context";

export default function MobileTournament({
  status,
  type,
  types,
  tournamentArr,
  setType,
  setStatus,
  setDetails,
  details,
  width,
  carouselItemsArr,
  isLoading
}) {
  const [registeredTournaments, setRegisteredTournaments] = useState([]);
  const [pastTournaments, setPastTournaments] = useState([]);
  const [user, setUser] = useState(null);
  const {userData} = useContext(AppContext)
  useEffect(() => {
    // Set user data when it's available
    if (userData) {
      setUser(userData);
    }
  }, [userData]);
  useEffect(() => {
    // Check if the user is already registered
    if (user && carouselItemsArr) {
      const registered = carouselItemsArr?.filter(tournament => tournament?.registrants.some(
        registrant => registrant.ctcId === user.ctcId || registrant.lichessId === user.lichessId
      )   )
      let participated = carouselItemsArr?.filter(tournament=>tournament.status== "past")
      participated = participated.filter(tournament => tournament?.registrants.some(
        registrant => registrant.ctcId === user.ctcId || registrant.lichessId === user.lichessId
      )   )
      setRegisteredTournaments(registered);
      setPastTournaments(participated)
      // console.log(registered);
    }
  }, [user, carouselItemsArr]);  
  const filteredTournaments = {
    Completed: pastTournaments,
    Upcoming: carouselItemsArr.filter(
      (elem) => elem.status === "future" && elem.tournamentType === type
    ),
    Registered: registeredTournaments,
  };

  return (
    <>
      <NavBar />
      <div
        className="mobileTournament"
        style={details ? { display: "none" } : {}}
      >
        <div className="head">
          <span className="sectionTitle">🏅Tournaments</span>
          <DropDown type={type} types={types} setType={setType} />
        </div>
        <div className="statusBlock">
          {tournamentArr.map((stat) => (
            <button
              className={`status headBtn ${status === stat ? "active" : ""}`}
              onClick={() => setStatus(stat)}
              key={stat}
            >
              {stat}
            </button>
          ))}
        </div>
        <div className="cards">
          {isLoading ? (
            Array.from({ length: 5 }).map((_, index) => (
              <SkeletonTournamentCard key={index} />
            ))
          ) : (
            <>
              {status === "Completed" && (
                filteredTournaments.Completed.length > 0 ? (
                  filteredTournaments.Completed.map((elem) => (
                    <TournamentCard
                      key={elem.id}
                      status={status}
                      card={elem}
                      setDetails={setDetails}
                    />
                  ))
                ) : (
                  <div className="no-entries">No entries yet</div>
                )
              )}
              {status === "Upcoming" && (
                filteredTournaments.Upcoming.length > 0 ? (
                  filteredTournaments.Upcoming.map((elem) => (
                    <TournamentCard
                      key={elem.id}
                      status={status}
                      card={elem}
                      setDetails={setDetails}
                    />
                  ))
                ) : (
                  <div className="no-entries">No entries yet</div>
                )
              )}
              {status === "Registered" && (
                filteredTournaments.Registered.length > 0 ? (
                  filteredTournaments.Registered.map((elem) => (
                    <TournamentCard
                      key={elem.id}
                      status={status}
                      card={elem}
                      setDetails={setDetails}
                    />
                  ))
                ) : (
                  <div className="no-entries">No entries yet</div>
                )
              )}
            </>
          )}
        </div>
      </div>
      {details && (
        <CardDetails setDetails={setDetails} width={width} details={details} />
      )}
      <Footer />
    </>
  );
}
