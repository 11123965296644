import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { AppContext } from '../Context/Context';


const ProtectedRoute = ({ children, redirectPath }) => {
  const isAuthenticated = useAuth();
 const { accessToken, setAccessToken} = useContext(AppContext)
  
  if (accessToken) {
    return children;
  }
  return <Navigate to={redirectPath} replace />;
 
};

export default ProtectedRoute;

