import { useEffect, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { AppContext } from "../Context/Context";
import { auth } from '../utils/firebase/Firebase';

export function useAuth() {
  const { isSigned, setSignIn } = useContext(AppContext);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setSignIn(!!user);
    });

    return () => unsubscribe();
  }, [isSigned]);

  return isSigned;
}
