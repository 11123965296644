import React, { useContext, useEffect, useState } from 'react';
import styles from './wallet.module.css';
import Navbar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import classNames from 'classnames';
import coinlogo from './wm-front.png';
import receiveicon from './Arrow up.png';
import sendicon from './Arrow down.png';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { AppContext } from '../../Context/Context';
import Banner from '../Banner/Banner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Wallet() {
    const [userWalletData, setUserWalletData] = useState(null);
    const [wallet, setWallet] = useState(true);
    const [referral, setReferral] = useState(false);
    const [transactions, setTransactions] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const { userData, setUserData } = useContext(AppContext);

    useEffect(() => {
        console.log(userData , 'userdata wallet')
        if (userData != null&& userData.wallet != null) {
            setUserWalletData(userData.wallet);
        } 
    }, [userData , setUserWalletData , userWalletData]);

    const walletFn = () => {
        setWallet(true);
        setReferral(false);
        setTransactions(false);
    };

    const referFn = () => {
        setReferral(true);
        setWallet(false);
        setTransactions(false);
    };

    const tsxnFn = () => {
        setTransactions(true);
        setReferral(false);
        setWallet(false);
    };

    const timestamp = (time) => {
        const date = new Date(time);
        return date.toISOString().replace('T', ' ').split('.')[0];
    };

    const showComingSoonToast = () => {
        toast.info("This feature is coming soon!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    return (
        <>
            <Navbar />
            <div className={styles.container}>
                {/* Wallet Section */}
                <div className={classNames(styles.walletsection, wallet ? '' : styles.displaynon)}>
                    <button className={classNames(styles.walletbut, styles.displaynon)}>Wallet</button>
                    <div className={styles.mobilebutcon}>
                        <button onClick={walletFn} className={wallet ? styles.mobilewalletbutactive : styles.mobilewalletbutinactive}>Wallet</button>
                        <button onClick={referFn} className={referral ? styles.mobilewalletbutactive : styles.mobilewalletbutinactive}>Referral</button>
                    </div>
                    <div className={styles.balanceinfo}>
                        <div className={styles.accbal}>
                            <div style={{ height: '90px', width: '295px' }}>
                                <div className={styles.accbalhed}>Account Balance</div>
                                <div className={styles.accbalamt}>₹ {userWalletData ? userWalletData.inr : '0'}</div>
                            </div>
                            <div className={styles.iconcont}><div className={styles.infologo}>i</div></div>
                        </div>
                        <div style={{ border: '0px' }} className={styles.accbal}>
                            <div style={{ height: '90px', width: '295px' }}>
                                <div className={styles.accbalhed}>CTc Coins</div>
                                <div style={{ display: 'flex', gap: '8px' }} className={styles.accbalamt}>
                                    <img src={coinlogo} alt="" />
                                    <div style={{ display: 'inline-block' }}>{userWalletData ? userWalletData.ctc : '0'}</div>
                                </div>
                            </div>
                            <div className={styles.iconcont}><div className={styles.infologo}>i</div></div>
                        </div>
                    </div>
                    <div className={styles.balance}>500</div>
                    <div className={styles.addmoneycont}>
                        <button className={styles.withdmo} onClick={showComingSoonToast}>Withdraw</button>
                        <button className={styles.addmo} onClick={showComingSoonToast}>Add Money</button>
                    </div>
                    <div className={styles.seetrxnmbtncn}>
                        <button onClick={tsxnFn} className={styles.seetrxnmbt}>All transactions</button>
                    </div>
                </div>

                {/* Referral Section */}
                <div className={classNames(styles.referralsection, referral ? '' : styles.displaynon)}>
                    <button className={classNames(styles.referralbut, styles.displaynon)}>Referral</button>
                    <div className={styles.mobilebutcon}>
                        <button onClick={walletFn} className={wallet ? styles.mobilewalletbutactive : styles.mobilewalletbutinactive}>Wallet</button>
                        <button onClick={referFn} className={referral ? styles.mobilewalletbutactive : styles.mobilewalletbutinactive}>Referral</button>
                    </div>
                    <div className={styles.referralcont}>
                        <div className={styles.comingSoon}>Coming Soon</div>
                    </div>
                </div>

                {/* Transaction Section */}
                <div className={classNames(styles.transactionsection, transactions ? '' : styles.displaynon)}>
                    <div className={styles.mobilebutcon}>
                        <button onClick={walletFn} className={wallet ? styles.mobilewalletbutactive : styles.mobilewalletbutinactive}>Wallet</button>
                        <button onClick={referFn} className={referral ? styles.mobilewalletbutactive : styles.mobilewalletbutinactive}>Referral</button>
                    </div>
                    <div className={styles.trsnbutcun}>
                        <button className={styles.trsnbut}>All transactions</button>
                        <button className={styles.filcashbut}>Filter: Cash</button>
                    </div>
                    <div className={styles.trxnreciptcnt}>
                        <div className={styles.trxnreciptboxreceive}>
                            {userWalletData ? (
                                userWalletData.history.map((e, index) => (
                                    <React.Fragment key={index}>
                                        <hr style={{ marginLeft: '57px', width: '282px' }} />
                                        <div className={styles.trxnreciptboxlft}>
                                            <div className={styles.receive}>
                                                <img src={e.type === 'DEBIT' ? sendicon : receiveicon} alt="" />
                                            </div>
                                            <div className={styles.amountadded}>
                                                <div style={{margin : "5px"}} className={styles.trxnheding}>Amount {e.type}</div>
                                                <div className={styles.trxndate}>{timestamp(e.timestamp)}</div>
                                            </div>
                                        </div>
                                        <div className={styles.trxnamt}>{`${e.currency} ${e.amount}`}</div>
                                    </React.Fragment>
                                ))
                            ) : (
                                <React.Fragment>
                                    {/* Placeholder for empty state or loading */}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.banner}>
                {isMobile ? null : <Banner wallet={true} />}
            </div>
            <div className={styles.space}></div>
            <Footer/>
            <ToastContainer />
        </>
    );
}

export default Wallet;
